import type { FunctionComponent } from "react";
import type { FragmentRefs } from "relay-runtime";

import type { VariantProps } from "scmp-app/components/section/section-top/variant/types";

import {
  HeroContentsContainer,
  HeroRest,
  StyledContentItemHomeSecondary,
  StyledContentItemSectionTopLeadPrimary,
} from "./styles";

type Props = {
  heroContentsGroup: {
    readonly " $fragmentSpreads": FragmentRefs<
      | "homeSecondaryContentItemContent"
      | "sectionTopLeadPrimaryContentItemContent"
      | "topStoriesItemContent"
    >;
  }[];
} & VariantProps;

export const TopicHeroContents: FunctionComponent<Props> = ({
  heroContentsGroup: [heroArticleLeading, heroOne, heroTwo],
}) => (
  <HeroContentsContainer>
    {heroArticleLeading && (
      <StyledContentItemSectionTopLeadPrimary reference={heroArticleLeading} withComment={true}>
        <HeroRest>
          {heroOne && (
            <StyledContentItemHomeSecondary
              reference={heroOne}
              skipHighlight={true}
              withComment={true}
            />
          )}
          {heroTwo && (
            <StyledContentItemHomeSecondary
              reference={heroTwo}
              skipHighlight={true}
              withComment={true}
            />
          )}
        </HeroRest>
      </StyledContentItemSectionTopLeadPrimary>
    )}
  </HeroContentsContainer>
);

TopicHeroContents.displayName = "TopicHeroContents";
